import React from "react";
import AnalyticsEvent from "../shared/AnalyticsEvent";

const Newsletter = () => {
  return (
    <div className="row">
      <div
        className="col-lg-6 order-lg-last"
        data-aos="fade-left"
        data-aos-duration="1200"
      >
        <div className="text-wrapper pl-3">
          <div className="title-style-three mb-35 md-mb-20">
            <h6>EduZ Tuition Newsletter Q1 2025</h6>
            <h2> 
              <span>
                Kickstart Your Year {" "}
                <img src="images/shape/line-shape-7.svg" alt="shape" />
              </span>
              with EduZ's Success Blueprint!
            </h2>
          </div>
          {/*  /.title-style-two */}
          <p>
          Dive into 2025 with our power-packed Q1 newsletter! Transform your study routine 
            with our curated Water Bodies Lofi playlist, master History with innovative 
            learning techniques, and get organized with our exclusive digital planning 
            tools. Plus, discover exciting March holiday activities, learn fascinating 
            facts about Chinese New Year celebrations, and get inspired by our special 
            interview with Science expert Tutor Johnathan. Whether you're looking to boost 
            your productivity or add some fun to your learning journey, we've packed everything 
            you need into one amazing resource!
          </p>
          <p className="mt-30">
          <a href="/docs/newsletter/EduZTuition-Q1-2025-Newsletter.pdf"
                target="_blank"
                rel="noreferrer"
                className="theme-btn-five"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                onClick={() => AnalyticsEvent({
                  category: "NewsletterDownload",
                  action: "Click",
                  source: "/",
                  label: "homepageNewsletterButton-q1-2025"
                })}
              >
                <i className="fa fa-file-pdf-o"></i> Download
              </a>
            </p>
        </div>
      </div>
      <div className="col-lg-6 order-lg-first">
        <div className="img-gallery">
        <a href="/docs/newsletter/EduZTuition-Q1-2025-Newsletter.pdf"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            onClick={() => AnalyticsEvent({
              category: "NewsletterDownload",
              action: "Click",
              source: "/",
              label: "homepageNewsletterImage-q1-2025"
            })}
          >
            <img
              src="images/site/homepage/newsletter/q1-2025-newsletter-1.webp"
              alt="media"
              data-aos="fade-right"
              data-aos-duration="1200"
              style={{border: "2px black solid"}}
            />
          </a>
          <img
            src="images/shape/49.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/54.svg"
            alt="shape"
            className="shapes shape-three"
          />
        </div>
        {/* /.img-gallery */}
      </div>
    </div>
  );
};

export default Newsletter;
